import { request } from "../utils/request";
//登录
import { versionfn } from "../utils/version";
var version = versionfn.version;
export function Login(data) {
  return request({
    url: "/login",
    method: "post",
    data,
  });
}
//验证码
export function securityCode(data) {
  return request({
    url: `${version}/securityCode`,
    method: "post",
    responseType: "arraybuffer",
    data,
  });
}
//刷新token
export function refreshToken(data) {
  return request({
    url: `${version}/refreshToken`,
    method: "post",
    data,
  });
}
// 获取谷歌验证器二维码
export function GoogleQr(name, secretKey) {
  return request({
    url: `${version}/getGoogleAuthQrcode?name=${name}&secretKey=${secretKey}`,
    method: "get",
    responseType: "blob",
  });
}
// 绑定谷歌验证码
export function bindingverCode(code, secretKey) {
  return request({
    url: `${version}/bindingGoogleAuthCode?code=${code}&secretKey=${secretKey}`,
    method: "post",
  });
}
// 校验谷歌验证码
export function checkverCode(code) {
  return request({
    url: `${version}/checkGoogleAuthCode?code=${code}`,
    method: "post",
  });
}
// 重置谷歌验证码
export function clearverCode(userId) {
  return request({
    url: `${version}/clearGoogleAuthCode?userId=${userId}`,
    method: "post",
  });
}
// 滑块验证码校验
export function gentCaptcha(data) {
  return request({
    url: `${version}/verifyIntelligentCaptcha`,
    method: "post",
    data,
  });
}
// 注册
export function Register(data) {
  return request({
    url: `${version}/channelUser/register`,
    method: "post",
    data,
  });
}
// 发送邮箱验证码
export function emailCode(data) {
  return request({
    url: `${version}/email/securityCode`,
    method: "post",
    data,
  });
}
// 邮箱注册
export function emailRegister(data) {
  return request({
    url: `${version}/channelUser/email/register`,
    method: "post",
    data,
  });
}
//修改密码
export function modifyPassword(data) {
  return request({
    url: `${version}/user/modifyPassword`,
    method: "put",
    data
  });
}