<template>
    <div class="register-main">
      <h2>重置密码</h2>
      <div class="retrievePassword-title" @click="jumpLogin">
        账号登录用户忘记密码请&nbsp;<span>联系客服</span>
      </div>
      <el-form
        :model="registerForm"
        label-position="left"
        :rules="registerrules"
        ref="registerForm"
        class="ruledfrom-style"
      >
        <el-form-item prop="email">
          <el-input
            v-model="registerForm.email"
            autocomplete="off"
            placeholder="请输入邮箱（必填）"
          ></el-input>
        </el-form-item>

        <el-form-item prop="captcha" class="code-style">
          
          <el-input
            v-model="registerForm.captcha"
            autocomplete="off"
            placeholder="请输入验证码"
          ></el-input>
          <el-button
            id="button"
            type="primary"
            @click="validateForm"
            v-if="deadlineboole"
          >
            获取验证码
          </el-button>
          <el-button class="numBtn" type="primary" v-else>
            <el-statistic
              ref="statistic"
              format="ss"
              @finish="hilarity"
              :value="deadline"
              time-indices
            >
            </el-statistic>
          </el-button>
        </el-form-item>
        <el-form-item prop="newpassword">
          <el-input
            type="password"
            v-model="registerForm.newpassword"
            autocomplete="off"
            placeholder="8-16个字符，至少1个字母，1个数字和1个特殊字符（必填）"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="surepassword">
          <el-input
            type="password"
            v-model="registerForm.surepassword"
            autocomplete="off"
            placeholder="确认密码（必填）"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item class="retrievePassword-style">
          <!-- <div class="register-set">
            <span @click="jumpLogin">使用已有账号登录</span>
          </div> -->
          <el-button class="retrievePassword-button" @click="registersubmit('registerForm')">确认</el-button>
          <el-button class="cancel-button" @click="canceledit">返回</el-button>

        </el-form-item>
      </el-form>
      <div v-if="iscontactkefu">
        <Contactkefu
          @handleClose="handleClose"
          :iscontactkefu="iscontactkefu"
        ></Contactkefu>
      </div>
    </div>
  </template>
  <script>
  import phonecode from "./phonecode.vue";
  import { modifyPassword,emailCode} from "@/aips/login";
  import global from "@/components/Global.vue";
  import Contactkefu from "@/views/Analysis/Contactkefu.vue";
  export default {
    prop: ["olduserBool"],
    components: {
      phonecode,
      Contactkefu
    },
    data() {
      return {
        iscontactkefu:false,
        loginresource: [],
        serviceImg: null,
        deadline: null,
        deadlineboole: true,
 
        registerForm: {
        email: "",
          phone: "",
          captcha: "",
          newpassword: "",
        },
    
        registerrules: {
            email: [
            { required: true, message: "请输入邮箱", trigger: "blur" },
            { required: false, type: 'email', message: '请输入正确的邮箱格式', trigger: ['blur', 'change'] }
          ],
          phone: [
            { required: true, message: "请输入手机号码", trigger: "blur" },
            {
              pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
              message: "请输入正确的手机号",
              trigger: "blur",
            },
          ],
          captcha: [
            { required: true, message: "请输入验证码", trigger: "blur" },
            {
              min: 6,
              max: 6,
              message: "验证码格式不对",
              trigger: "blur",
            },
          ],
          newpassword: [
            {
              required: true,
              message:
                "8-16个字符，至少1个字母，1个数字和1个特殊字符[$@$!%*#?&](必填)",
              trigger: "blur",
            },
            {
              min: 8,
              max: 16,
              message: "8-16个字符，至少1个字母，1个数字和1个特殊字符[$@$!%*#?&]",
              trigger: "blur",
            },
            {
              pattern:
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&.])[A-Za-z\d$@$!%*#?&.]{8,16}$/,
              message: "密码必须包含至少1个字母、1个数字和1个特殊字符[.$@$!%*#?&]",
              trigger: "blur",
            },
          ],
          surepassword: [
            { required: true, validator: this.confirmPassFn, trigger: "blur" },
          ],
        },
      };
    },
  
    methods: {
       //联系客服
    handleClose() {
      this.iscontactkefu = false;
    },
    jumpLogin() {
     
      this.iscontactkefu = true;
    },
      hilarity() {
        this.deadlineboole = true;
      },
      canceledit(){
        let datavalue = 0;
        this.$emit("changepage", datavalue);
      },
      // 获取验证码
      async validateForm() {
        console.log("获取验证码");
        let data = {
            email: this.registerForm.email,
            operationType:'CHANGE_PASSWORD'
        };
        // 1.向后端发起业务请求，获取验证码验证结果和业务结果
        const response = await emailCode(data);
        console.log("我是响应");
        console.log(response);
        // let result = false;
        if (response.code == 200) {
          this.deadline = Date.now() + 1000 * 60;
          this.deadlineboole = false;
          // result = true;
        } else {
          return;
        }
      },
      //  确认两次密码
      confirmPassFn(rule, value, callback) {
        console.log(value);
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== this.registerForm.newpassword) {
          callback(new Error("两次输入密码不一致!"));
        } else {
          callback();
        }
      },
     
      handleClosesuccess() {

        let data = 0;
        this.$emit("changepage", data);
      },
   
      // 注册
      registersubmit(formName) {
        console.log(222);
        
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // if (this.deadlineboole) {
            //   this.$message({
            //     message: "请先获取验证码或验证码已过期！",
            //     type: "warning",
            //     duration: 1000,
            //   });
            //   return;
            // }
            console.log(222);
            
            let data = {
              password: this.registerForm.newpassword,
              type: 'EMAIL',
              authCode: this.registerForm.captcha,
              account: this.registerForm.email,
            };
            modifyPassword(data)
              .then((res) => {
                console.log(res); 
                if (res.status == 200) {
                  
                  let datavalue = 0;
                  this.$emit("changepage", datavalue);
                } else {
                  // this.$message({
                  //   message: "注册失败请重试！",
                  //   type: "warning",
                  //   duration: 1000,
                  // });
                }
              })
              .catch((err) => {
                this.$message.error("修改失败请重试");
                this.registerForm.newpassword = "";
           
                this.registerForm.captcha = "";
                this.registerForm.email = "";
     
              });
          } else {
            //console.log("error submit!!");
            return false;
          }
        });
      },
    },
    created() {
      global.getConfig();
    },
    mounted() {
      setTimeout(() => {
        global.getConfigdata.forEach((resitem) => {
          if (resitem.action == "LOGIN") {
            this.loginresource = resitem.configs;
          }
        });
        this.loginresource.forEach((item) => {
          //微信客服二维码地址
          if (item.configKey == "wechatCustomerQrCode") {
            this.serviceImg = item.configValue;
          }
        });
      }, 1000);
    },
  };
  </script>
  <style lang="less" scoped>
  @import "@/assets/css/newlogin.less";
  ::v-deep.el-statistic{
  color:#fff !important
}
  </style>
  <style lang="less">
  @import "@/assets/css/publicStyle/dialog.less";
  </style>
  