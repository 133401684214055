<template>
  <div class="homeview-style theme-background">
    <div class="homeview-header">
      <div v-if="userdisable">
        <Newmymenu />
      </div>
      <div v-else>
        <Homeheader></Homeheader>
      </div>
    </div>
    <!-- 内容 -->
    <Content class="content-style" />
    <div v-if="ishome">
      <homeFooter></homeFooter>
    </div>
    <div v-else>
      <Footer v-if="$route.meta.showfooter"></Footer>
    </div>
  </div>
</template>
<script>
import Footer from "@/components/newtheme/otherfooter.vue";
import homeFooter from "@/components/newtheme/homefooter.vue";

import Homeheader from "@/views/newtheme/newhome/homeheader.vue";
import Newmymenu from "@/views/newtheme/newhome/initilarolemenu.vue";
import Content from "./Content.vue";
import { refreshToken } from "@/aips/login";
export default {
  components: {
    Homeheader,
    Content,
    Newmymenu,
    Footer,
    homeFooter,
    // Quitsavedialog
  },
  provide() {
    return {
      reload: this.reload, //把方法传递给下级组件
    };
  },
  data() {
    return {
      t_k: "",
      time: "",
      userdisable: false,
      ishome: false,
      // isquitsavedialog:false,
    };
  },
  computed: {
    address() {
      const { time, t_k } = this;
      return { time, t_k };
    },
  },

  methods: {
    // quitsavedialogClose(){
    //   this.isquitsavedialog=false
    //   localStorage.removeItem("temporarily");
    // }
  },
  mounted() {
    console.log(333);
    // if(localStorage.temporarily==true){
    //       this.isquitsavedialog=true
    //     }else{
    //       this.isquitsavedialog=false
    //     }
    // setTimeout(() => {
    //   this.isquitsavedialog=localStorage.temporarily
    // }, 500);
  },
  watch: {
    address(e) {
      // console.log(localStorage.temporarily);
      setTimeout(() => {
        this.time = Date.now();
        // if(localStorage.User){

          if (localStorage.t_k == undefined) {
            this.displaynotify.isShow = true;
            this.$message("登录过期请重新登录");
            // setTimeout(() => {
            //   refreshToken({ refreshToken: token.refreshToken }).then((res) => {
            //     //console.log(res);
            //   });
  
            // }, 2000);
            this.$router.push("/login");
          }
        // }
        //  if(localStorage.temporarily){
        //   this.isquitsavedialog=true
        // }else{
        //   this.isquitsavedialog=false
        // }
      }, 2000);
      let token = JSON.parse(localStorage.token);
      if (e.time == token.expireTime) {
        refreshToken({ refreshToken: token.refreshToken }).then((res) => {
          //console.log(res);
        });
      }
    },
    "$route.path"(toPath, fromPath) {
      if (toPath == "/home") {
        this.ishome = true;
      } else {
        this.ishome = false;
      }
    },
  },
  created() {
    if (this.$route.path == "/home") {
      this.ishome = true;
    } else {
      this.ishome = false;
    }
    window.addEventListener("resize", this.resize);
    this.t_k = localStorage.t_k;
    this.time = localStorage.time;
    let userroles = JSON.parse(localStorage.roles);
    console.log(userroles);
    userroles.sort(function (a, b) {
      return b.weight - a.weight;
    });

    if (userroles[0].label == "initial_role") {
      this.userdisable = true;
    } else {
      this.userdisable = false;
    }
    // 返回值等于-1 说明数组Arr中不存在id为date的对象
    //     if( userroles.findIndex(item => item.label=== 'initial_role' )!==-1){
    // this.userdisable=true
    //     }
    // //console.log(this.$router)
  },
};
</script>
<style lang="less">
.homeview-style {
  .homeview-header {
    background: transparent;
  }

  .content-style {
    overflow-y: scroll;
  }
  .content-style::-webkit-scrollbar {
    display: none;
  }
}
</style>
