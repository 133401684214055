<template>
  <div class="homefooter">
    <div class="leftimg">
      <div>
        <img v-if="logoimgurl" :src="logoimgurl" alt="logo" />
        <el-skeleton style="width: 240px" v-else>
          <template slot="template">
            <div style="display: flex; align-items: center">
              <el-skeleton-item
                variant="image"
                style="width: 30px; height: 30px; background: #1c2634"
              />

              <el-skeleton-item
                variant="p"
                style="
                  width: 144px;
                  margin-left: 6px;
                  height: 20px;
                  background: #1c2634;
                "
              />
            </div>
          </template>
        </el-skeleton>
      </div>
      <div class="leftbottom-content">成都北鸣电子科技有限公司</div>
    </div>
    <div class="righttext">
      <div class="righttext-content">
        <h1>研判分析</h1>
        <div class="content-title">
          <router-link to="/home/caseAnalysis"
            ><span>案件分析</span></router-link
          >
          <router-link to="/home/balanceselect"
            ><span>余额分析</span></router-link
          >
          <router-link to="/home/labelselect"
            ><span>标签查询</span></router-link
          >
          <router-link to="/home/modelanalysis"
            ><span>模型分析</span></router-link
          >
          <router-link to="/home/addressMonitor"
            ><span>地址监控</span></router-link
          >
        </div>
      </div>
      <div class="righttext-content">
        <h1>调证管理</h1>
        <div class="content-title">
          <router-link to="/adjustment/advise"
            ><span>调证建议</span></router-link
          >
          <router-link to="/adjustment/querysuspects-list"
            ><span>调证用户</span></router-link
          >
          <router-link to="/adjustment/querysuspects-Upload"
            ><span>调证上传</span></router-link
          >
          <router-link to="/adjustment/investigation"
            ><span>调证协查</span></router-link
          >
        </div>
      </div>
      <div class="righttext-content">
        <h1>数据看板</h1>
        <div class="content-title">
          <router-link to="/datacase/transactiondata"
            ><span>交易数据</span></router-link
          >
          <router-link to="/datacase/balancedata"
            ><span>余额数据</span></router-link
          >
        </div>
      </div>
      <div class="righttext-content">
        <h1>帮助中心</h1>
        <div class="content-title">
          <router-link to="/help/Product-function"
            ><span>产品功能介绍</span></router-link
          >
          <router-link to="/help/National-involvment"
            ><span>司法判例参考</span></router-link
          >
          <router-link to="/help/prosecuteBook"
            ><span>起诉书</span></router-link
          >
          <router-link to="/help/judgmentBook/政策法规"  v-if="expertServiceMenulist.showPolicyRegulation"
            ><span>政策法规</span></router-link
          >
          <router-link to="/help/judgmentBook/区块链行业知识"  v-if="expertServiceMenulist.showBlockchainKnowledge"
            ><span>区块链行业知识</span></router-link
          >
          <router-link to="/help/judgmentBook/区块链案件侦查实录" v-if="expertServiceMenulist.showBlockchainCaseInvestigation"
            ><span>区块链案件侦查实录</span></router-link
          >
        </div>
      </div>
      <div class="righttext-content">
        <h1>联系我们</h1>
        <div class="content-titleimg">
          <span class="titleimg-info">
            <img :src="publicImg" alt="" />
            <span>微信公众号</span>
          </span>
          <span class="titleimg-info">
            <img :src="serviceImg" alt="" />
            <span>微信客服</span>
          </span>
        </div>
      </div>

      <!-- <span>成都北鸣电子科技有限公司</span> -->
      <!-- <div></div>
        <span>备案编号：蜀ICP备2023003901号.</span> -->
    </div>
  </div>
</template>
<script>
import global from "@/components/Global.vue";
import { expertServiceMenu } from "@/aips/policyRegulation";

export default {
  data() {
    return {
      logoimgurl: null,
      publicImg: null,
      serviceImg: null,
      userdisable: false,
      expertServiceMenulist: {
        showBlockchainCaseInvestigation: false,
        showBlockchainKnowledge: false,
        showPolicyRegulation: false,
      },
    };
  },
  mounted() {
    // document.addEventListener("click", (e) => {
    //   const personage = document.getElementById("personage");
    //   if (personage) {
    //     if (!personage.contains(e.target)) {
    //       this.ispersonage = false;
    //     }
    //   }
    // });
    let userroles = JSON.parse(localStorage.roles);
    console.log(userroles);
    userroles.sort(function (a, b) {
      return b.weight - a.weight;
    });
    this.userroles = userroles[0].value;
    if (
      sessionStorage.logoimgurl &&
      sessionStorage.publicImg &&
      sessionStorage.serviceImg
    ) {
      let logoimg = JSON.parse(sessionStorage.logoimgurl);
      let publicImg = JSON.parse(sessionStorage.publicImg);
      let serviceImg = JSON.parse(sessionStorage.serviceImg);

      this.logoimgurl = logoimg;
      this.publicImg = publicImg;
      this.serviceImg = serviceImg;
    } else {
      this.getGlobalInfo();
    }
  },
  methods: {
    getexpertServiceMenu() {
      expertServiceMenu().then((res) => {
        console.log(res);

        this.expertServiceMenulist.showBlockchainCaseInvestigation =
          res.data.showBlockchainCaseInvestigation;
        this.expertServiceMenulist.showBlockchainKnowledge =
          res.data.showBlockchainKnowledge;
        this.expertServiceMenulist.showPolicyRegulation =
          res.data.showPolicyRegulation;
      });
    },
    getGlobalInfo() {
      setTimeout(() => {
        console.log(global.getConfigdata);
        global.getConfigdata.forEach((resitem) => {
          if (resitem.action == "LOGIN") {
            this.loginresource = resitem.configs;
          }
        });
        this.loginresource.forEach((item) => {
          if (item.configKey == "logo") {
            this.logoimgurl = item.configValue;
            sessionStorage.logoimgurl = JSON.stringify(this.logoimgurl);
          }
        });
        this.loginresource.forEach((item) => {
          //微信客服二维码地址
          if (item.configKey == "wechatCustomerQrCode") {
            this.serviceImg = item.configValue;
            sessionStorage.serviceImg = JSON.stringify(this.serviceImg);
          }
          // 微信公众号二维码地址
          if (item.configKey == "wechatPublicQrCode") {
            this.publicImg = item.configValue;
            sessionStorage.publicImg = JSON.stringify(this.publicImg);
          }
        });
      }, 500);
    },
  },
  created() {
    let userroles = JSON.parse(localStorage.roles);
    userroles.sort(function (a, b) {
      return b.weight - a.weight;
    });

    if (userroles[0].label == "initial_role") {
      this.userdisable = true;
    } else {
      this.userdisable = false;
    }
    this.getexpertServiceMenu();
  },
};
</script>
<style lang="less" scoped>
.homefooter {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  color: #fff;
  padding: 80px 0;
  // align-items: center;

  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  background: #070d15;
  .leftimg {
    display: flex;
    //   align-items: center;
    justify-content: space-between;

    flex-direction: column;
    img {
      height: 31.94px;
    }
    .leftbottom-content {
      display: inline-block;
      color: var(--Gray-6, #f2f2f2);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      opacity: 0.5;
    }
  }
  .righttext {
    color: var(--web-color-text-tertiary, #a2a9b6);
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    //   align-items: center;
    .righttext-content {
      margin-right: 80px;
      display: flex;
      flex-direction: column;
      > h1 {
        color: var(--Gray-6, #f2f2f2);
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .content-title {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        span {
          display: inline-block;
          color: var(--Gray-6, #f2f2f2);
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
          opacity: 0.5;
        }
      }
      .content-titleimg {
        margin-top: 20px;
        display: flex;
        .titleimg-info {
          display: flex;
          flex-direction: column;
          margin-right: 8px;
          align-items: center;
          > img {
            width: 90px;
            height: 90px;
            flex-shrink: 0;
            border-radius: 4px;
          }
          > span {
            margin-top: 4px;
            color: var(--Gray-6, #f2f2f2);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
    //   div {
    //     width: 1px;
    //     height: 14px;
    //     flex-shrink: 0;
    //     background: var(--web-color-icon-quarternary, #bac0ca);
    //     margin: 0 12px;
    //   }
  }
}
</style>
