<template>
  <div class="login-style">
    <div class="logo-top">
      <img :src="logoimg" alt="" />
    </div>
    <!-- <div class="login-video-box">
      <video id="loginvideo" autoplay muted loop class="login-video">
        <source src="@/assets/image/videobg.mp4" type="video/mp4" />
      </video>
    </div> -->
    <div class="login-content-main">
      <div class="logo-header">
        <div class="header-content">
          <!-- <div class="logo-top">
            <img :src="logoimg" alt="" />
          </div> -->

          <span class="logo-title">虚拟货币安全智能<br />研判系统</span>
          <span class="content-main"
            >以链上交易溯源及匿名身份穿透为核心，打击涉虚拟货币<br />新型犯罪的一站式查控平台</span
          >
          <span class="content-bottom"> -&nbsp;欢迎登录图链&nbsp;- </span>
        </div>
      </div>
      <div class="login-left"></div>
      <div class="login-right">
        <div class="login-content" v-if="olduserBool == 0">
          <h2>用户登录</h2>
          <div class="black-tabs">

            <el-tabs
              class="tabs-style"
              v-model="activeName"
              @tab-click="handleClickTab"
       
            >
              <!-- <el-tab-pane label="画布分析" name="canvasAnalysis"
                ><canvasAnalysisTab
                  @canvasJump="canvasJump"
                  @getHistory="getHistory"
                ></canvasAnalysisTab>
              </el-tab-pane> -->
              <!-- <el-tab-pane label="账号" name="usernamelogin"> -->

                <el-form
            :model="loginForm"
            label-position="left"
            :rules="rules"
            ref="loginForm"
            class="ruledfrom-style"
            @submit.native.prevent
          >
            <el-form-item prop="name" label="账号">
              <div class="input-img">
                <el-input
                  v-model="loginForm.name"
                  autocomplete="off"
                  placeholder="请输入账号"
                ></el-input>
                <!-- <img src="@/assets/image/icon_adm.png" alt="" class="icon-adm" /> -->
              </div>
            </el-form-item>
            <el-form-item prop="password" label="密码">
              <div class="input-img">
                <el-input
                  type="password"
                  v-model="loginForm.password"
                  autocomplete="off"
                  placeholder="请输入密码"
                  show-password
                ></el-input>
                <!-- <img src="@/assets/image/icon_pas.png" alt="" class="icon-pas" /> -->
              </div>
            </el-form-item>
            <!-- <el-form-item prop="authCode">
            <el-input
              v-model="loginForm.authCode"
              placeholder="请输入验证码"
            ></el-input>
            <img
              src="../../assets/image/icon_cap.png"
              alt=""
              class="icon-cap"
            />
            <div class="sms-style">
              <button
                v-if="isShow"
                @click="textBtn"
                :disabled="
                  loginForm.name != '' && loginForm.password != ''
                    ? false
                    : true
                "
              >
                点击获取
              </button>

              <el-image
                v-else
                :src="loginForm.imgSrc"
                @click="send_sms1"
              ></el-image>
            </div>
          </el-form-item> -->
            <el-form-item>
              <el-button @click="submitForm('loginForm')">登录</el-button>
              <div class="loginForm-bottom">
                <span class="forgetpassword-style" @click="forgotPassword"
                  ><span class="bottom-title">忘记密码？</span></span
                >
                <!-- <span>登录即视为您已同意 
                    <span class="bottom-title">隐私条款</span>
                  </span> -->
                  <div class="password-set">
            <!-- <el-checkbox v-model="checked">记住密码</el-checkbox> -->
            <span @click="registerpage"
              >还没有账户？<span class="set-title">注册</span></span
            >
          </div>
              </div>
            </el-form-item>
          </el-form>

              <!-- </el-tab-pane> -->
              <!-- <el-tab-pane label="邮箱" name="emailslogin"
                >
          <el-form
            :model="loginForm"
            label-position="left"
            :rules="rules"
            ref="loginForm"
            class="ruledfrom-style"
            @submit.native.prevent
          >
            <el-form-item prop="name" label="邮箱">
              <div class="input-img">
                <el-input
                  v-model="loginForm.name"
                  autocomplete="off"
                  placeholder="请输入邮箱"
                ></el-input>
             
              </div>
            </el-form-item>
            <el-form-item prop="password" label="密码">
              <div class="input-img">
                <el-input
                  type="password"
                  v-model="loginForm.password"
                  autocomplete="off"
                  placeholder="请输入密码"
                  show-password
                ></el-input>
              
              </div>
            </el-form-item>
       
            <el-form-item>
              <el-button @click="submitForm('loginForm')">登录</el-button>
              <div class="loginForm-bottom">
                <span class="forgetpassword-style" @click="forgotPassword"
                  ><span class="bottom-title">忘记密码？</span></span
                >
           
                  <div class="password-set">
          
            <span @click="registerpage"
              >还没有账户？<span class="set-title">注册</span></span
            >
          </div>
              </div>
            </el-form-item>
          </el-form></el-tab-pane
              > -->
     
            </el-tabs>
          </div>
      
        </div>
        <div class="login-content1" v-if="olduserBool == 1">
          <div>
            <div class="vercodenum">
              <div>验证码</div>
            </div>
            <el-input
              v-model="trendsVerCode"
              placeholder="请输入动态验证码"
              @keydown.enter.native="keydownCode"
            ></el-input>
            <div class="minbox">
              <span @click="isopenhelp">如何查看Google身份验证器？</span>
              <span class="returnlogin" @click="handleClose2"
                >返回登录页面</span
              >
            </div>
            <el-button @click="suretrendsVerCode">确认</el-button>
          </div>
        </div>
        <div class="register-content" v-if="olduserBool == 2">
          <!-- <h2>用户注册</h2>
        <el-form
          :model="registerForm"
          label-position="left"
          :rules="registerrules"
          ref="registerForm"
          class="ruledfrom-style"
        >
          <el-form-item prop="account">
            <el-input
              v-model="registerForm.account"
              autocomplete="off"
              placeholder="请输入用户名（必填）"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input
              type="phone"
              v-model="registerForm.phone"
              autocomplete="off"
              placeholder="请输入手机号（必填）"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="phonecodeboole">
            <phonecode
              @getdata="getdata"
              :registerForm="registerForm"
            ></phonecode>
          </el-form-item>
          <el-form-item prop="captcha" v-if="!phonecodeboole">
            <el-input
              v-model="registerForm.captcha"
              autocomplete="off"
              placeholder="请输入验证码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="newpassword" v-if="!phonecodeboole">
            <el-input
              type="password"
              v-model="registerForm.newpassword"
              autocomplete="off"
              placeholder="6 - 16位密码，区分大小写（必填）"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item prop="surepassword" v-if="!phonecodeboole">
            <el-input
              type="password"
              v-model="registerForm.surepassword"
              autocomplete="off"
              placeholder="确认密码（必填）"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item v-if="!phonecodeboole">
            <div class="register-set">
              <span @click="jumpLogin">使用已有账号登录</span>
            </div>
            <el-button @click="registersubmit('registerForm')">注册</el-button>
          </el-form-item>
        </el-form>
        <el-dialog
          :close-on-click-modal="false"
          :visible.sync="registersuccess"
          :before-close="handleClosesuccess"
          width="25%"
          top="12%"
        >
          <el-result
            icon="success"
            title="注册成功"
            subTitle="添加企业微信申请更多服务"
            class="resultbox"
          >
            <template slot="extra">
              <div class="retrunlogin">
                <img src="@/assets/image/weixin.png" alt="" />
               
              </div>
            </template>
          </el-result>
        </el-dialog> -->
          <register
            @changepage="changepage"
            :olduserBool="olduserBool"
          ></register>
        </div>
        <div class="register-content" v-if="olduserBool == 3">
      
          <retrievePassword
            @changepage="changepage"
            :olduserBool="olduserBool"
          ></retrievePassword>
        </div>
      </div>
      <!-- <div class="login-footer"> -->
      <!-- <span>制作人：陈琛、李仁业 |</span>
      <span>指导人：黄世和 |</span>
      <span>制作单位：永嘉县公安局经侦大队（虚拟币犯罪研判工作室）</span> -->
      <!-- </div> -->
      <div class="login-footer" v-html="configValue1"></div>
      <div v-if="iscontactkefu">
        <Contactkefu
          @handleClose="handleClose"
          :iscontactkefu="iscontactkefu"
        ></Contactkefu>
      </div>
      <el-dialog
        class="dialog-style-public"
        :visible.sync="newuserBool"
        width="1080px"
        top="10px"
        title="Google 身份验证器绑定方式"
        :before-close="handleClose1"
        :close-on-click-modal="false"
      >
        <binding
          :googleform="googleform"
          @handleClose1="handleClose1"
        ></binding>
      </el-dialog>
      <el-dialog
        class="dialog-style-public"
        :visible.sync="seeCodeBool"
        width="1000px"
        title="如何使用谷歌验证码"
        :before-close="handleClose3"
        :close-on-click-modal="false"
      >
        <codegoogle
          :googleform="googleform"
          :handleClose3="handleClose3"
        ></codegoogle>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// securityCode, LoginDynamic
import {
  Login,
  securityCode,
  checkverCode,
  Register,
  refreshToken,
} from "@/aips/login";
import Contactkefu from "@/views/Analysis/Contactkefu.vue";
import retrievePassword from "./retrievePassword.vue";

import binding from "./binding.vue";
import codegoogle from "./codegoogle.vue";
import global from "@/components/Global.vue";
import register from "./register.vue";  
// import register from "./emailregister.vue"; 
export default {
  components: {
    Contactkefu,
    binding,
    codegoogle,
    register,
    retrievePassword
  },
  data() {
    return {
      activeName:'usernamelogin',
      // registersuccess: false,
      logoimg: null,
      configValue1: null,
      loginresource: [],
      seeCodeBool: false,
      trendsVerCode: null, //验证码
      olduserBool: 0,
      googleform: {},
      newuserBool: false,
      isSms: true,
      isShow: true,
      iscontactkefu: false,
      checked: "",
      loginForm: {
        name: "",
        password: "",
        // authCode: "",
        imgSrc: "",
        type: "",
      },
      // 注册
      // phonecodeboole: true,
      // registerForm: {
      //   account: "",
      //   phone: "",
      //   captcha: "",
      //   newpassword: "",
      // },
      rules: {
        name: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 2,
            max: 16,
            message: "长度在 2 到 16 个字符",
            trigger: "blur",
          },
        ],
        sms: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
      },
    
    };
  },
  watch: {},
  methods: {
   
       // tab切换
       handleClickTab(v) {},
    registerpage() {
      this.olduserBool = 2;
      this.loginForm.password = "";
      this.loginForm.name = "";
    },
    // 注册成功返回登录页面
    changepage(data) {
      console.log("我是data");
      console.log(data);
      this.olduserBool = data;
      localStorage.clear();
    },
    // jumpLogin() {
    //   this.olduserBool = 0;
    // },
    onSubmit(e) {
      if (e.keyCode == 13) {
        this.submitForm("loginForm");
      }
    },

    keydownCode(e) {
      var keyCode1 = window.event ? e.keyCode : e.which;
      //回车执行查询
      if (keyCode1 == 13) {
        console.log(this.trendsVerCode);
        this.suretrendsVerCode();
      }
    },
    isopenhelp() {
      this.seeCodeBool = true;
    },
    suretrendsVerCode() {
      this.displaynotify.isShow = true;
      let token1 = JSON.parse(localStorage.token);
      let time1 = Date.now();
      if (time1 >= token1.expireTime) {
        this.olduserBool = 0;
        this.$message("验证时间过长，请重新登录！");
        console.log("超过时间了");
        localStorage.clear();
        // refreshToken({ refreshToken: token1.refreshToken }).then((res) => {
        //   console.log(res);
        // });
        return;
      }
      //console.log(this.trendsVerCode);
      checkverCode(this.trendsVerCode).then((res) => {
        // console.log(res);
        if (res.code === 200) {
          this.$message({
            message: "登录成功",
            type: "success",
            duration: 1000,
          });
          if (localStorage.t_k) {
            window.localStorage.clear();
          }
          let time = Date.now();
          //console.log(time);
          localStorage.time = time;
          localStorage.token = JSON.stringify(res.data.token);
          localStorage.t_k = res.data.token.accessToken;
          localStorage.roles = JSON.stringify([...res.data.roles]);
          this.displaynotify.isShow = true;
          if (localStorage.redirectUrl) {
            const redirectUrl = JSON.parse(localStorage.redirectUrl);
            console.log("我是路由拦截器");
            console.log(redirectUrl);
            // 清除已存储的跳转 URL
            // localStorage.removeItem("redirectUrl");
            // 跳转到之前记录的页面
            this.$router.push(redirectUrl);
          } else {
            this.$router.push("/home");
          }
        }
      });
    },
    handleClose1() {
      this.newuserBool = false;
    },
    handleClose3() {
      this.seeCodeBool = false;
    },
    handleClose2() {
      this.olduserBool = 0;
      localStorage.clear();
    },
    //联系客服
    handleClose() {
      this.iscontactkefu = false;
    },
    forgotPassword() {
      // this.olduserBool = 3;
      this.iscontactkefu = true;
    },
    // 登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          Login({
            username: this.loginForm.name,
            password: this.loginForm.password,
            // authCode: this.loginForm.authCode,
          })
            .then((res) => {
              console.log(res);
              if (res.code == 300) {
                // this.$message.error("账号、密码或验证码错误");
                return;
              }
              if (res.data.bindingFlag == 0) {
                this.newuserBool = true;
                this.googleform = res.data;
              }
              if (res.data.bindingFlag == 1) {
                this.olduserBool = 1;
                this.googleform = res.data;
              }
              if (localStorage.t_k) {
                window.localStorage.clear();
              }
              let time = Date.now();
              //console.log(time);
              console.log("到这里来了");
              localStorage.time = time;
              localStorage.token = JSON.stringify(res.data.token);
              localStorage.t_k = res.data.token.accessToken;
           
            })
            .catch((err) => {
              // this.$message.error("账号、密码或验证码错误");
              this.loginForm.password = "";
              this.loginForm.authCode = "";
              this.loginForm.name = "";
            });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
  
  },
  created() {
    localStorage.clear();
    sessionStorage.clear();

    global.getConfig();
  },
  mounted() {
    // if (location.href.indexOf("#reloaded") == -1) {
    //   location.href = location.href + "#reloaded";
    //   location.reload();
    // }

    window.addEventListener("keyup", this.onSubmit, false);
    setTimeout(() => {
      global.getConfigdata.forEach((resitem) => {
        console.log(resitem);

        if (resitem.action == "LOGIN") {
          this.loginresource = resitem.configs;
        }
      });
      this.loginresource.forEach((item) => {
        if (item.configKey == "logo") {
          this.logoimg = item.configValue;
          console.log(this.logoimg);
        }
        if (item.configKey == "copyright") {
          this.configValue1 = item.configValue;
        }
      });
    }, 1000);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onSubmit, false);
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/newlogin.less";
.forgetpassword-style {
  cursor: pointer;
}

</style>
<style lang="less">
@import "@/assets/css/publicStyle/tabs.less";

.el-dialog {
  // top: -10%;
  // position: relative;
  // width: 824px;
  // background: #0f212f;
  // border: 1px solid rgba(64, 95, 104, 0.57);
}
</style>
